<!--
 * @Author: cyy
 * @Date: 2021-12-06 10:30:20
 * @LastEditTime: 2023-03-10 15:23:58
 * @LastEditors: cyy 1346967714@qq.com
 * @Description: 商品订单和代金券订单
 * @FilePath: \teacherdingding.com\src\views\Order\cashCoupon\cashcouponOrder.vue
-->
<template>
  <div id="opencassOrder">
    <template v-if="$route.path === '/capitalOrder'">
      <div class="header">
        <div class="heard_top mt20">
          <span>订单类型</span>
          <el-select size="medium" v-model="post.order_type" placeholder="" style="width: 148px">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="秒杀" :value="6"></el-option>
          </el-select>
        </div>

        <div class="heard_top mt20">
          <span>付款时间</span>
          <el-date-picker size="medium" style="width: 292px" v-model="orderTime" type="daterange" @change="paytimepick"
            value-format="timestamp" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </div>
        <el-select class="ml20 mr20 mt20" v-model="post.status" size="medium" placeholder="请选择">
          <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>

        <el-select size="medium" v-model="post.pay_way" placeholder="" class="mt20" style="width: 148px">
          <el-option label="全部支付方式" value="0"></el-option>
          <el-option label="微信" value="1"></el-option>
          <el-option label="支付宝" value="2"></el-option>
          <template v-if="type == 2">
            <el-option label="花呗分期" value="3"></el-option>
          </template>
        </el-select>

        <el-input clearable size="medium" :placeholder="type == 1
        ? post.search_type == 1
          ? '输入订单号搜索'
          : '请输入付款学员名称搜索'
        : post.search_type == 1
          ? '请输入付款学员名称搜索'
          : '输入订单号搜索'
      " v-model="post.search" class="input-with-select mt20 ml20">
          <div class="search_select" slot="prepend">
            <el-select style="width: 120px" v-model="post.search_type" placeholder="请选择">
              <el-option v-for="item in type == 1 ? options3 : options2" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </el-input>
        <div class="heard_top mt20">
          <span>提现状态</span>
          <el-select v-model="post.is_freeze" slot="prepend" placeholder="请选择">
            <el-option v-for="item in payoutOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
      </div>

      <div class="header2 flex-center">
        <div class="left flex1">
          <span>累计订单收入(元)：{{ find.price }}</span>
          <span class="ml50">累计订单(笔)：{{ find.all_count }}</span>
        </div>
        <el-button @click="exportTable" type="primary" size="medium" class="mr20">
          导出全部
        </el-button>
      </div>

      <pagination2 @complete="complete" ref="child" :option="post"
        :url="type == 1 ? '/LiveGoods/orderList' : '/LiveGoods/goodsOrderList'">
        <template v-slot:default="{ tableData: { list } }">
          <el-table :data="list" :header-cell-style="{
      background: 'rgba(245,245,245,1)',
      color: '#333333',
    }">
            <el-table-column label="订单号" prop="number"></el-table-column>

            <el-table-column label="付款学员" min-width="100px">
              <template slot-scope="{ row }">
                <div class="paymember cp" @click="studentDetail(row)" title="点击跳转至学员详情页面">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="font1" :title="`${row.uname}${!row.student_remarks
        ? ''
        : '(' + row.student_remarks + ')'
      }`
      ">
                    {{ row.uname }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="商品" prop="goods_name" v-if="type == 2">
              <template slot-scope="{ row }">
                <div class="tu_name">
                  <div class="tu">
                    <img :src="row.goods_cover_img" alt="" />
                  </div>
                  <div class="name" :title="row.goods_name">
                    {{ row.goods_name }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="代金券" prop="goods_name" v-else></el-table-column>

            <el-table-column label="售价(元)">
              <template slot-scope="{ row }">
                ￥{{ row.selling_price }}
              </template>
            </el-table-column>

            <el-table-column label="实付(元)" prop="price">
              <template slot-scope="{ row }">
                ￥{{ row.price }}
                <div style="color: #ff3535; font-size: 12px" v-if="row.is_coupon == 1">
                  使用了优惠券
                </div>

                <div style="color: #ff3535; font-size: 12px" v-if="row.is_seckill == 1">
                  参与了秒杀活动
                </div>
              </template>
            </el-table-column>

            <el-table-column label="付款时间" prop="order_time">
              <template slot-scope="{ row }">
                {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
              <!-- formatTimeStamp('yyyy-MM-dd hh:mm') -->
            </el-table-column>

            <el-table-column label="状态" prop="costprice">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color: #ff3535">
                  未支付
                </span>
                <span v-else-if="row.status == 5" style="color: #ff3535">
                  退款中
                </span>
                <span v-else-if="row.status == 6" style="color: #ff3535">
                  已退款
                </span>
                <span v-else style="color: #46932c">支付成功</span>
                <!-- 支付成功 、已过期 -->
              </template>
            </el-table-column>

            <el-table-column label="提现状态">
              <template slot-scope="{ row }">
                <div v-if="row.is_freeze == 1" style="color: #FF3F3F">
                  不可提现
                </div>
                <div v-else-if="row.is_freeze == 2" style="color: #45942C">
                  可提现
                </div>
                <div v-else>
                  -
                </div>
              </template>
            </el-table-column>
            <el-table-column label="支付方式">
              <template slot-scope="{ row }">
                <div v-if="row.pay_way == 1">微信</div>
                <div v-if="row.pay_way == 2">支付宝</div>
                <div v-if="row.pay_way == 3">花呗分期</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button type="text" @click="
      $router.push({
        path:
          '/capitalOrder/cashcouponDetail/' +
          row.live_goods_order_id,
      })
      ">
                    详情
                  </el-button>
                  <template v-if="row.status == 2 &&
      row.pay_way == 1 &&
      Number(row.price) > 0 &&
      row.payee == 1
      ">
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click="orderDrawback(row)">
                      退款
                    </el-button>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>
    <router-view />
  </div>
</template>
<script>
import { payoutOptions } from '@/utils/index'
export default {
  name: 'opencassOrder',
  data() {
    return {
      find: {},
      options4: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 2,
          label: '支付成功',
        },
        {
          value: 1,
          label: '未支付',
        },
        {
          value: 5,
          label: '退款中',
        },
        {
          value: 6,
          label: '已退款',
        },
      ],
      post: {
        search_type: this.type == 1 ? 1 : 2, //    搜索条件  1订单号  2学员
        search: '',
        pay_way: '0',
        status: 0,
        start_time: 0,
        end_time: 0,
        order_type: 0,
        is_freeze: 0,
      },
      total: 0,
      orderTime: '',

      options2: [
        {
          value: 2,
          label: '订单号',
        },
        {
          value: 1,
          label: '付款学员',
        },
      ],

      options3: [
        {
          value: 1,
          label: '订单号',
        },
        {
          value: 2,
          label: '付款学员',
        },
      ],
      payoutOptions,
    }
  },

  props: {
    // 1代金券 2，商品
    type: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    // 退款
    async orderDrawback({ live_goods_order_id }) {
      this.$confirm(
        '发起退款后，请联系并告知工作人员，3个工作日内完成退款！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/order/orderDrawback',
            data: {
              order_type: this.type == 1 ? 5 : 6,
              live_goods_order_id,
            },
            successMsg: true,
          })
          this.$refs.child.reset()
        })
        .catch(() => { })
    },

    studentDetail(row) {
      let url = this.$router.resolve({
        path: `/manageStudent/detail?uid=${row.user_id}`,
      })
      this.$store.dispatch('open', url.href)
    },

    //获取列表的数据
    complete(val) {
      this.total = val.count
      this.find = val && val.data && val.data.find ? val.data.find : {}
    },

    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.post.start_time = val[0] / 1000
      this.post.end_time = val[1] / 1000 + 86399
    },

    // 导出
    exportTable() {
      const verify = this.total

      if (verify > 0) {
        this.$confirm(`确定要导出当前${verify}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(
            _.assign({}, this.post, {
              orderType: this.type,
            })
          )

          this.$store.dispatch(
            'open',
            `${location.origin
            }/liveGoods/exportExcel?query=${encodeURIComponent(query)}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .input-with-select .el-input__inner {
  width: 218px !important;
}

::v-deep .search_select {
  .el-input__inner {
    width: 160px !important;
  }
}

#opencassOrder {
  padding: 0 20px 20px;

  .sp_diver {
    margin: 0 10px;
    color: #1b9d97;
  }

  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -10px;

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }

      .el-input-group__prepend {
        background-color: #fff;
      }
    }
  }

  .header2 {
    margin-bottom: 10px;

    .left {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }

  .membership {
    display: flex;
    align-items: center;

    .membership_photo {
      margin-right: 10px;

      img {
        width: 64px;
        height: 36px;
      }
    }

    .font1 {
      width: calc(100% - 74px);
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .paymember {
    display: flex;
    align-items: center;

    .member_photo {
      margin-right: 10px;

      img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
      }
    }

    .font1 {
      width: calc(100% - 34px);
      font-size: 12px;
      font-weight: 500;
      color: blue;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .tu_name {
    align-items: center;
    display: flex;

    .tu {
      width: 30px;
      height: 30px;
      background: #dedede;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      width: calc(100% - 40px);
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .btns {
    display: flex;
    align-items: center;
  }

  .el-input {
    width: 338px;
  }

  ::v-deep .el-input--medium .el-input__inner {
    width: 148px;
  }
}

.heard_top {
  padding: 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;

  span {
    margin-right: 24px;
    min-width: 56px;
  }

  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }

  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }

    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
</style>
