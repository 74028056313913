<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 18:41:04
 * @LastEditTime: 2023-03-10 15:24:04
 * @LastEditors: cyy 1346967714@qq.com
 * @Description: 图文订单
 * @FilePath: \teacherdingding.com\src\views\Order\graphic\graphicOrder.vue
-->
<template>
  <div class="graphicOrder">
    <div class="top">
      <div class="search">
        <el-form ref="ruleIngredientsForm" label-width="70px" :model="search" class="flex-center" size="small">
          <el-form-item class="mr20" label="订单状态">
            <el-select v-model="search.status" size="medium" placeholder="请选择" style="width: 148px">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="mr20" label="支付方式">
            <el-select size="medium" v-model="search.pay_way" placeholder="" style="width: 148px">
              <el-option label="全部" value="0"></el-option>
              <el-option label="微信" value="1"></el-option>
              <el-option label="支付宝" value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="订单类型">
            <el-select size="medium" v-model="search.order_type" placeholder="" style="width: 148px">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="秒杀" :value="6"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键词" class="mr20">
            <div class="search-item search-item1 ">
              <div class="vm select">
                <el-select v-model="search.search_type" size="medium">
                  <el-option v-for="item in options1" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="vm input">
                <el-input :placeholder="placeholder" v-model="search.search" size="medium" clearable></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="付款时间" class="mr20">
            <div class="vm select ">
              <el-date-picker style="width:360px" v-model="search.order_time" @change="
          selectedTime(arguments[0], [
            'start_order_time',
            'end_order_time',
          ])
          " type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                unlink-panels value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
                size="medium"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="mr20" label="提现状态">
            <el-select v-model="search.is_freeze" size="medium" placeholder="请选择" style="width: 148px">
              <el-option v-for="item in payoutOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-button size="medium" type="primary" style="margin-bottom:18px" @click="toSearch">
            搜索
          </el-button>
        </el-form>
      </div>
    </div>
    <pagination2 :option="search2" @complete="complete" class="childDialogBox" url="/Graphic/buyOrder" ref="childDialogBox">
      <template v-slot:default="{ tableData: { find, list } }">
        <div class="tip">
          <div>
            <span class="mr20">累计订单收入(元)：{{ find.price }}</span>
            <span>累计订单(笔)：{{ find.count }}</span>
          </div>
          <div>
            <el-button @click="exportTable" type="primary" size="medium" class="mr20">
              导出全部
            </el-button>
          </div>
        </div>
        <el-table :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
        }" :data="list">
          <el-table-column label="订单号" prop="number"></el-table-column>

          <el-table-column label="用户" min-width="100px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="手机号">
            <template slot-scope="{ row }">{{ row.umobile || '-' }}</template>
          </el-table-column>
          <el-table-column label="售价">
            <template slot-scope="{ row }">
              {{ row.selling_price }}
            </template>
          </el-table-column>
          <el-table-column label="实付">
            <template slot-scope="{ row }">
              {{ row.price }}
              <div class="redcolor fs12" v-if="row.is_seckill == 1">
                参与了秒杀活动
              </div>
            </template>
          </el-table-column>
          <el-table-column label="付款时间" width="180px">
            <template slot-scope="{ row }">
              {{ row.order_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="{ row }">
              <span v-if="row.status == 1" style="color: #ff3535">
                未支付
              </span>
              <span v-else-if="row.status == 5" style="color: #ff3535">
                退款中
              </span>
              <span v-else-if="row.status == 6" style="color: #ff3535">
                已退款
              </span>
              <span v-else style="color: #46932c">支付成功</span>
            </template>
          </el-table-column>
          <el-table-column label="提现状态">
            <template slot-scope="{ row }">
              <div v-if="row.is_freeze == 1" style="color: #FF3F3F">
                不可提现
              </div>
              <div v-else-if="row.is_freeze == 2" style="color: #45942C">
                可提现
              </div>
              <div v-else>
                -
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="
          $router.push({
            path: '/capitalOrder/graDetails',
            query: { id: row.g_order_id },
          })
          ">
                详情
              </el-button>
              <template v-if="row.status == 2 &&
          row.pay_way == 1 &&
          Number(row.price) > 0 &&
          row.payee == 1
          ">
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="orderDrawback(row)">
                  退款
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
import { payoutOptions } from '@/utils/index'
// import formFields from './formFields'
export default {
  name: 'graphicOrder',
  props: {
    // 1代金券 2，商品
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      fieldsList: [],

      search: {
        search: '',
        order_time: [],
        search_type: 1,
        start_order_time: '',
        end_order_time: '',
        status: 0,
        order_type: 0,
        pay_way: '0',
        is_freeze: 0,
      },

      options4: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 2,
          label: '支付成功',
        },
        {
          value: 1,
          label: '未支付',
        },
        {
          value: 5,
          label: '退款中',
        },
        {
          value: 6,
          label: '已退款',
        },
      ],

      search2: {},

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],

      options2: [
        { label: '全部', value: 0 },
        { label: '待审核', value: 1 },
        { label: '已拒绝', value: 2 },
      ],
      total: 0,
      payoutOptions,
    }
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search)
  },

  methods: {
    // 退款
    async orderDrawback({ g_order_id }) {
      this.$confirm(
        '发起退款后，请联系并告知工作人员，3个工作日内完成退款！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/order/orderDrawback',
            data: {
              order_type: 7,
              g_order_id,
            },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => { })
    },

    // 搜索
    toSearch() {
      this.search2 = _.assign({}, this.search)
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },
    
    //获取列表的数据
    complete(val) {
      this.total = val.count
    },
    // 导出
    exportTable() {
      const verify = this.total

      if (verify > 0) {
        this.$confirm(`确定要导出当前${verify}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(
            _.assign({}, this.search, {
              orderType: this.type,
            })
          )

          this.$store.dispatch(
            'open',
            `${location.origin
            }/Graphic/exportExcel?query=${encodeURIComponent(query)}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.graphicOrder {
  padding: 0 20px 20px;

  .top {
    .search {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }

      ::v-deep .el-form {
        flex-wrap: wrap;
      }

      .search_type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .el-form-item__content {
          height: auto;
        }

        .el-form-item {
          width: 33.3%;
          margin-bottom: 20px;
        }
      }

      ::v-deep .el-input,
      ::v-deep .select {
        width: 100%;

        .el-input__inner {
          width: 100%;
        }
      }

      ::v-deep .search-item1 {
        display: flex;
        width: 300px;

        .select {
          width: 130px;

          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .input {
          width: 100%;

          input {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      ::v-deep .search-item2,
      ::v-deep .search-item6 {
        .select {
          width: 160px;
        }
      }

      ::v-deep .search-item3,
      ::v-deep .search-item4 {
        .el-date-editor {
          width: 292px;
        }

        .el-input__icon {
          line-height: 27px;
        }
      }

      ::v-deep .search-item5,
      ::v-deep .search-item7,
      ::v-deep .search-item8 {
        width: 300px;

        .el-input {
          // width: 130px;
          position: relative;

          input[type='number'] {
            padding-right: 50px;
          }

          .el-input-group__append {
            position: absolute;
            right: 0;
            background: none;
            top: 6px;
            height: 100%;
            border: 0;
            // line-height: 36px;
            width: auto;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .childDialogBox {
    .tip {
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .user_contain {
      display: flex;
      align-content: center;

      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
