<!--
 * @Author: cyy
 * @Date: 2021-12-17 17:45:24
 * @LastEditTime: 2022-09-01 10:13:53
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\Order\index.vue
-->
<template>
  <div id="orderindex">
    <el-tabs v-model="activeName" v-if="$route.path === '/capitalOrder'">
      <el-tab-pane label="课程订单" name="1"></el-tab-pane>
      <el-tab-pane label="会员订单" name="2"></el-tab-pane>
      <el-tab-pane label="资料订单" name="3"></el-tab-pane>
      <el-tab-pane label="公开课订单" name="4"></el-tab-pane>
      <el-tab-pane label="代金券订单" name="5"></el-tab-pane>
      <el-tab-pane label="商品订单" name="6"></el-tab-pane>
      <el-tab-pane label="图文订单" name="7"></el-tab-pane>
    </el-tabs>

    <Order v-if="activeName == '1'"></Order>

    <menber-oder v-if="activeName == '2'"></menber-oder>

    <orderfrom-data :type="2" v-if="activeName == '3'" :isNeedPayout="true"></orderfrom-data>

    <opencass-order v-if="activeName == '4'"></opencass-order>

    <cashcoupon-order
      v-if="activeName == '5'"
      :type="1"
      :key="activeName"
    ></cashcoupon-order>

    <cashcoupon-order
      v-if="activeName == '6'"
      :type="2"
      :key="activeName"
    ></cashcoupon-order>

    <graphic-order v-if="activeName == '7'" :key="activeName"></graphic-order>
  </div>
</template>

<script>
import graphicOrder from './graphic/graphicOrder'
import cashcouponOrder from './cashCoupon/cashcouponOrder'
import opencassOrder from './openCourse/opencassOrder'
import menberOder from './menberOder'
import Order from './Order'
import bg from '@/mixin/background'
import orderfromData from '@cm/base/orderfromData'
export default {
  name: 'orderindex',

  mixins: [bg],

  components: {
    Order,
    menberOder,
    orderfromData,
    opencassOrder,
    cashcouponOrder,
    graphicOrder,
  },

  data() {
    return {
      activeName: '1',
    }
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
#orderindex {
  background-color: #fff;
  margin: 20px;
  ::v-deep .el-tabs__nav-scroll {
    padding: 0 20px;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
}
</style>
